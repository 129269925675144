import React, {useState} from 'react';
import { yellow } from '@mui/material/colors';
import Button from '@mui/material/Button';
import { createTheme } from '@mui/material/styles';
import './App.css';
import { ThemeProvider } from '@emotion/react';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import loader from './loading.svg';
import { signUp, unsubscribe } from './client';

const theme = createTheme({
  palette: {
    primary: {
      main: yellow[50],
    },
    secondary: {
      main: '#11cb5f',
    },
  },
});



const NavigationBar = () => {
  const user = "jeremy";
  const domain = "jeremyscott.io";
  return <div className="nav-bar">
    <div className="titles">
      <Title></Title>
      <Navigatable title={'Home'} path={'/'}></Navigatable>
    </div>

    <ThemeProvider theme={theme}>
      <Button variant="contained"
              id="getintouch-header"
              onClick={(event) => {
                event.preventDefault();
                window.location.href = `mailto:${user}@${domain}`
              }}>
        Get in touch
      </Button>
    </ThemeProvider>

  </div>
};

const Navigatable = () => {
  const title = window.location.pathname !== '/about' ? 'About' : 'Home';
  const path = window.location.pathname !== '/about' ? '/about' : '/';

  const onClick = (event) => {
    event.preventDefault();
    window.location.pathname = path;
  }

  return <h2
      onClick={onClick}
      className='navigatable'
  ><span>{title}</span></h2>;
}

const Title = () =>  <h1 id="navigatable"><span>Go Shiritori</span></h1>

const AboutShiritori = () => {
  return <div className={"about"}>
    <p>Shiritori is a Japanese word game. It can be used to improve recall of vocabulary.
    At Go Shiritori, we have developed a system that plays the game with you,
      and profiles areas that you struggle with. It then adjusts it's strategy
      to help improve your recall.</p>

    <p>The rules of Go Shiritori are as follows:</p>
    <ol>
      <li>The first word may be any word not starting with "n"(ん).</li>
      <li>All subsequent words must start with the list character played.</li>
      <li>A player who uses "n"(ん) loses.</li>
      <li>Words may not be repeated.</li>
    </ol>

    <p>As you progress, our system will begin to provide detailed feedback on areas
    you have trouble recalling. We provide a comprehensive timeline of all games played,
    along with the progression of your recall.</p>
  </div>;
}

const Body = () => {
  const landingPage=<><Slogan></Slogan>
    <ValuePropositions/></>;
  const about = <AboutShiritori />;
  const body = window.location.pathname === '/about' ? about : landingPage;
  const user = "jeremy";
  const domain = "jeremyscott.io";
  return <>
    <div className="body">
      {body}

    </div>

  </>
};

const LoadingSpinner = () => <img src={loader} style={{margin: '0 auto'}} />;

const SignUp = () => {
  const [email, setEmail] = useState('');
  const [isSubmitted, setSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const updateEmail = (event) => {
    event.preventDefault();
    const email = event.target.value;
    setEmail(email);
  }

  const onClick = async (event) => {
    event.preventDefault();
    console.log('Hello World');
    setIsLoading(true);

    try{
      await signUp(email);
    }
    catch(e){
      console.log(e);
    }

    setIsLoading(false);
    setSubmitted(true);
  }

  const content = isSubmitted ? <Alert
        severity="success"
        style={{maxWidth: "325px"}}
    >Thank-you! We'll be in touch.</Alert> :<div className="signup-fields">
      <TextField
          id="email"
          value={email}
          onChange={updateEmail}
          type="email"
          label="Email"
          variant="filled"
          InputLabelProps={{style: {color: "#ffffff"}}}/>
      <Button
          onClick={onClick}
          disabled={!email.includes('@')}
          id="signup-trigger"
          variant="contained">Sign Up</Button>
    </div>

  return <form>
    <ul className="signup-items">
      <li>
        <h1 id={"call-to-action"}>Signup for early access today</h1>
        <ThemeProvider theme={theme}>
          { isLoading ? 
              <LoadingSpinner /> :
              content}
        </ThemeProvider>
      </li>

    </ul>


  </form>
}

const Slogan = () => <h2 className='slogan'>Gamify learning <span className='focus'>Japanese</span>.</h2>;

const ValuePropositions = () => <div className="copy">
  <div className="image-placeholder"></div>
  <div className='value-propositions'>
    <ul className='value-statements'>
      <li>Learn Japanese playing <a href="/about" id="shiritori">Shiritori</a>.</li>
      <li>Discover knowledge gaps with <span className='focus'>detailed feedback</span>.</li>
      <li>Game <span className='focus'>constantly evolves</span> to improve your recall.</li>
    </ul>
    <SignUp></SignUp>
  </div>
</div>

const Unsubscribe = ({id}) => {
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);


  const onClick = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    await unsubscribe(email, id);
    setIsSubmitted(true);
    setIsLoading(false);
  }

  const onChange = (event) => {
    event.preventDefault();
    setEmail(event.target.value);
  }

  return <div className={"body"}>
    {
      isLoading ? <LoadingSpinner></LoadingSpinner>
          : isSubmitted ? <h3>Done! You've unsubscribed :(.</h3> : <form>
            <TextField
                onChange={onChange}
                label={"Email"}>
            </TextField>
            <Button
                onClick={onClick}
                variant={"contained"}>Unsubscribe</Button>
          </form>
    }
  </div>
}

function getId(location, searchString) {
  return location.replace(searchString, '');
}

function App() {
  const location = window.location.pathname;

  const searchString = '/waitlist/unsubscribe';
  return (
      <div className="App">
        <NavigationBar></NavigationBar>
        {location.includes(searchString) ?
            <Unsubscribe id={getId(location, searchString)} />:
            <Body></Body>}

      </div>
  );
}

export default App;
