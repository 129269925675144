
export const signUp = async (email) => {
    const url = process.env.REACT_APP_URL;
    const path = 'waitlist';
    const body = JSON.stringify({
        Email: email
    });

    await new Promise((resolve) => setTimeout(resolve, 1000))
    await fetch(url + path,{
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: body
    });
};

export const unsubscribe = async (email, id) => {
    const url = process.env.REACT_APP_URL;
    const path = `waitlist/unsubscribe`;
    const body = JSON.stringify({
        Email: email,
    });

    await new Promise((resolve) => setTimeout(resolve, 1000))
    await fetch(url + path,{
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: body
    });
}